import axios from 'axios'

const state = {
    selectedClass: null
};

const getters = {
    getSelectedClass: state => state.selectedClass
};

const actions = {

    getClassesConstants: async () => {
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_INTRANET_API_URL + "form/classes", {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                resolve(response.data.data);
            })
            .catch((error) => reject(error));
        });
    },

    getGenericClasses: async (_, class_id ) => {
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_API_URL + "ammon/classes/" + class_id, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                if (Object.keys(response.data).length > 0){
                    resolve(response.data[Object.keys(response.data)[0]].id)
                } else {
                    resolve('')
                }
            })
            .catch((error) => reject(error));
        });
    },

    getSpecialties: async (_, class_id) => {
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_API_URL + "ammon/classes/" + class_id +"/specialties", {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => reject(error));
        });
    },

    getHighSchools: async (_, searchQuery) => {
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_API_URL + "ammon/schools/list", {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                params: {
                    search: searchQuery
                }
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => reject(error));
        });
    },

    updateSelectedClass({ commit }, datas) {
        commit('updateSelectedClass', datas);
    }
}

const mutations = {

    updateSelectedClass(state, datas) {
        state.selectedClass = datas
    }
};

export default {
	state,
	getters,
	actions,
	mutations
};