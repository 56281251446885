<template>
     <v-row justify=center class="mt-4">
        <v-col cols=9>
            <login-header/>
            <div class="text-justify text-subtitle-1 mt-15">
                <p>Les infrastructures Cours-Thales sont en maintenance. Les inscriptions restent possibles au <b>01 42 05 41 36</b>. <br>Nous revenons très vite !</p>
                <p></p>
                <!-- <p class="mb-6">Nous nous excusons pour le désagrément occasionné et vous remercions de votre compréhension.</p> -->
                <p class="text-right">L'équipe Cours Thalès.</p>
            </div>
        </v-col>
    </v-row>
</template>

<script>

import LoginHeader from '@/components/layout/HeaderBase.vue';

export default {
    components: {
        LoginHeader
    },
};

</script>