<template>
    <v-row v-if="!paymentSuccess">
        <v-col v-if="showPaymentButton" cols=12 class="text-start mt-5 pb-0">
            <div>Merci, votre inscription a bien été prise en compte !</div>
            <div class="d-none d-sm-block">
                Un email récapitulatif vous a été envoyé avec les détails et le lien pour vous connecter à votre espace personnel.
            </div>
            <div class="d-sm-none">
                Un email récapitulatif vous a été envoyé avec un lien de connexion.
            </div>
            <div class="mt-3 d-none d-sm-block">
                <b>Prochaine étape ?</b> Vous devez <b>confirmer votre inscription</b> en procédant au paiement sécurisé en ligne.
            </div>
        </v-col>
        <v-col cols=12>
            <v-checkbox v-if="showPaymentButton" v-model="conditions" hide-details="true">
                <template v-slot:label>
                    <span class="checkbox-label text-start ml-1">
                    J'ai lu et j'accepte les 
                    <a href="https://www.cours-thales.fr/cgv" target="_blank" rel="noopener noreferrer">Conditions Générales de Vente</a>.
                    </span>
                </template>
            </v-checkbox>
        </v-col>
        <v-col cols=12>
            <v-row class="flex-column" v-if="showPaymentButton">
                <v-col class="text-start pt-2">
                    <v-btn @click="createPaymentForm" class="font-weight-bold d-sm-none w-100 mb-2" color="secondary" :disabled="!conditions">
                        P<span class="text-lowercase">rocéder au paiement</span>
                    </v-btn>
                    <v-btn class="font-weight-bold d-sm-none w-100" color="primary">
                        <a href="https://app.hubspot.com/meetings/marc-serban?uuid=cb0b4d09-b738-4a9f-ad49-37419f3bf360" target="_blank" class="text-white btn-recall">Me faire rappeler</a>
                    </v-btn>
                    <v-btn @click="createPaymentForm" class="font-weight-bold d-none d-sm-block" color="secondary" :disabled="!conditions">
                        P<span class="text-lowercase">rocéder au paiement pour confirmer</span>
                    </v-btn>
                    <v-btn class="font-weight-bold d-none d-sm-block mt-2" color="primary">
                        <a href="https://app.hubspot.com/meetings/marc-serban?uuid=cb0b4d09-b738-4a9f-ad49-37419f3bf360" target="_blank" class="text-white btn-recall">
                            Me faire rappeler par un conseiller avant de confirmer
                        </a>
                    </v-btn>
                </v-col>
            </v-row>
        </v-col>
        <v-col v-if="showPaymentButton" cols=12 class="mt-4 my-3 pb-0 text-start font-weight-medium">
            <div class="d-none d-sm-block">
                Pour un paiement en plusieurs fois,
                nous proposons un prélèvement SEPA.
                Veuillez contacter le service client
                au <b>01 42 05 41 36</b> ou envoyer un mail
                à <a href="mailto:contact@cours-thales.fr">contact@cours-thales.fr</a>.
            </div>
            <div class="d-sm-none">
                <b>Possibilité de paiement en plusieurs fois</b> :
                Veuillez nous contacter au 01 42 05 41 36 ou par mail
                à <a href="mailto:contact@cours-thales.fr">contact@cours-thales.fr</a>.
            </div>
        </v-col>
        <v-col v-if="showPaymentButton" cols="12" class="mt-4 my-3 text-start font-weight-medium d-md-none">
            <v-card flat class="py-3 recap-card">
                <span class="part-title font-weight-bold d-block mb-4 recap-title">RECAPITULATIF DE VOTRE DEMANDE D'INSCRIPTION</span>
                <div v-for="(childSession, index) in sortedCart" :key="index" class="mt-3 mb-6">
                    <div class="font-weight-medium text-primary mb-3 recap-child" >Pour {{ index }}</div>
                    <v-row v-for="(session, sessionIndex) in childSession" :key="sessionIndex" class="mt-1 mb-2">
                        <v-col cols="12" class="d-flex justify-space-between py-1">
                            <div>
                                <span class="font-weight-bold product-mobile">{{ session.session.product.name }}</span>
                            </div>
                            <div class="d-flex align-center">
                                <span class="font-weight-bold price-mobile item-price">{{ getItemPrice(session, childSession) }}€</span>
                            </div>
                        </v-col>
                        <v-col cols="12" class="py-0">
                            <span class="session-infos d-block recap-infos">Du {{ formatDate(session.session.start_date) }} au {{ formatDate(session.session.end_date) }}</span>
                        </v-col>
                        <v-col cols="12" class="py-0">
                            <span v-if="session.session.location" class="d-block recap-infos">{{ session.session.location.description }} ({{ session.session.location.city }} {{ session.session.location.postcode }})</span>
                        </v-col>
                    </v-row>
                </div>
                <v-row v-if="totalPrice > 0" justify="space-between" class="px-3 mt-4 pt-3">
                    <span class="font-weight-bold">Total</span>
                    <span class="font-weight-bold">{{ totalPrice }}€</span>
                </v-row>
            </v-card>
        </v-col>
        <v-row v-if="loading" justify=center class="flex-column font-weight-bold mt-12">
            <v-col class="pb-0">
                Nous créons l'inscription aux stages réservés, cette opération peut prendre quelques secondes.
            </v-col>
            <v-col class="d-flex justify-center">
                <div class="spinner">
                    <div class="bounce1"></div>
                    <div class="bounce2 ml-1"></div>
                    <div class="bounce3 ml-1"></div>
                </div>
            </v-col>
        </v-row>
        <v-row v-if="loadingPayment" justify=center class="flex-column font-weight-bold mt-12">
            <v-col class="pb-0">
                Paiement en cours d’enregistrement.
                Veuillez patienter cette action peut prendre quelques secondes.
            </v-col>
            <v-col class="d-flex justify-center">
                <div class="spinner">
                    <div class="bounce1"></div>
                    <div class="bounce2 ml-1"></div>
                    <div class="bounce3 ml-1"></div>
                </div>
            </v-col>
        </v-row>
        <v-row v-if="showPaymentForm" id="paymentForm" class="mt-4 mb-2">
            <v-col cols=12>
                <div>
                    Veuillez saisir vos informations de paiement.<br>
                    Nous ne stockons ni votre numéro de carte, ni le code de sécurité.
                </div>
            </v-col>
            <v-col cols=12 class="d-flex justify-center">
                <div class="kr-smart-form"></div>
            </v-col>
        </v-row>
        <v-row v-if="paymentError" class="text-red text-start font-weight-bold mt-3 px-3">
            Une erreur est survenue lors du paiement. Vous pouvez nous contacter au 01 42 05 41 36.
        </v-row>
    </v-row>

    <v-row v-else class="px-1 mt-10">
        <v-col cols=12 sm=11 md=12 lg=10 xl=8 class="text-start">
            <v-icon icon="mdi-check-circle-outline" color="success" size="80"></v-icon>
            <p class="text-justify mt-8 payment-success-text">
                Merci, votre paiement a bien été reçu ! <br>
                Vous pouvez accéder aux détails de votre inscription depuis votre compte.<br>
                Les informations de connexion vous ont été envoyées par email.<br>
                Nos conseillers pédagogiques sont joignables au 01 42 05 41 36.
            </p>
            <a :href="intranetUrl" target="_blank" class="text-white text-decoration-none font-weight-bold">
                <v-btn color="primary" class="mt-3 d-sm-none">
                        Votre compte
                </v-btn>
            </a>
            <a :href="intranetUrl" target="_blank" class="text-white text-decoration-none font-weight-bold">
                <v-btn color="primary" class="mt-5 d-none d-sm-block">
                        Accéder à votre compte
                </v-btn>
            </a>
        </v-col>
    </v-row>
</template>

<script>

import { mapGetters } from 'vuex';

import KRGlue from "@lyracom/embedded-form-glue";
import moment from "moment";

export default {

    data() {
        return {
            cart: null,
            cartIds: null,
            intranetUrl: process.env.VUE_APP_API_INTRANET,
            invoiceId: null,
            invoiceIdKey: 'currentInvoiceId',
            conditions : false,
            formToken: null,
            loading: false,
            loadingPayment: false,
            paymentError: false,
            paymentSuccess: false,
            showPaymentButton: true,
            showPaymentForm: false,
            sortedCart: null,
            classesCodeTab: {
                'Seconde': 'CLA-2DE',
                'Première': 'CLA-1RE',
                'Terminale': 'CLA-TER',
                'Maths Sup': 'CLA-MSUP',
                'Maths Spé': 'CLA-MSPE',
                'Prépa ECG 1ère année': 'CLA-ECG1',
                'Prépa ECG 2ème année': 'CLA-ECG2'
            }
        }
    },

    computed: {

        ...mapGetters([
            'getUser',
            'getCart',
            'getDatalayerInfo'
        ]),

        sectors() {
            var sectors = ""
            Object.values(this.getCart).map(session => {
                const productName = session.session.product.name
                if (sectors.length > 0) {
                    sectors += ", " + productName
                } else {
                    sectors = productName
                }
            })
            return sectors
        },

        totalPrice() {
            var price = 0;
            Object.keys(this.sortedCart).map(key => {
                this.sortedCart[key].map(session => {
                    price += parseInt(this.getItemPrice(session, this.sortedCart[key]))
                })
            })
            return price
        }

    },

    methods: {

        createDatalayer(event) {
            // Filtrer les sessions qui n'ont pas encore été envoyées
            const unsentSessions = this.getUnsentSessions();

            // Si des sessions n'ont pas encore été envoyées, création du datalayer
            if (unsentSessions.length > 0) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': event,
                    'email': this.getUser.email,
                    'user': 'new_user',
                    'new_customer': true,
                    'filière': this.sectors,
                    'amount': this.calculateTotalPrice(unsentSessions)
                })

                // Mise à jour des sessions envoyées dans le localStorage
                const newSessionsSent = this.getDatalayerInfo.concat(unsentSessions.map(session => `${session.user.infos.id}_${session.session.id_ammon}`));
                this.$store.dispatch('updateDatalayerInfo', newSessionsSent)
            }
        },

        calculateTotalPrice(unsentSessions) {
            let price = 0;

            Object.keys(this.sortedCart).forEach(key => {
                this.sortedCart[key].forEach(session => {
                    const sessionId = session.session.id_ammon;
                    const userId = session.user.infos.id;

                    // Vérifie si la session concerne un utilisateur non traité
                    if (unsentSessions.some(unsentSession => unsentSession.session.id_ammon === sessionId && unsentSession.user.infos.id === userId)) {
                        price += parseInt(this.getItemPrice(session, this.sortedCart[key]));

                        // Marque la session comme traitée
                        unsentSessions = unsentSessions.filter(unsentSession => !(unsentSession.session.id_ammon === sessionId && unsentSession.user.infos.id === userId));
                    }
                });
            });

            return price;
        },

        async errorPayment() {
            this.paymentError = true
            this.loadingPayment = false
        },

        formatDate(date) {
            return moment(date).format('DD/MM/YYYY');
        },

        isCartMatchingDatalayer() {
            const currentCartSessions = new Set();
            Object.values(this.sortedCart).forEach(sessions => {
                sessions.forEach(session => {
                    currentCartSessions.add(`${session.user.infos.id}_${session.session.id_ammon}`);
                });
            });

            const datalayerSessions = new Set(this.getDatalayerInfo);

            return currentCartSessions.size === datalayerSessions.size && 
                    [...currentCartSessions].every(session => datalayerSessions.has(session));
        },

        generateInvoice() {
            this.showPaymentForm = false
            this.loading = true
            const datas = {
                cart: this.getCart.map((item) => {
                    return { 'sessionId': item.session.id_ammon, 'userId': item.user.infos.id, 'class': item.session.class }
                }),
                user: this.getUser,
            };
            this.$store.dispatch('generateInvoice', datas)
                .then((response) => {
                    this.invoiceId = response.invoice_id;
                    this.saveInvoiceId(this.invoiceId);
                    this.loading = false
                    this.showPaymentButton = true
                    this.createDatalayer('inscription_autre')
                    this.updateDatalayerWithCurrentCart();
                    this.$emit('update-title', 'Validation de votre pré-inscription')
                })
                .catch((error) => console.log(error));
        },

        async createPaymentForm() {
            const datas = {
                user: this.getUser,
                invoice_id: this.invoiceId,
            };
            const response = await this.$store.dispatch('createSystemPayPayment', datas);

            this.formToken = response.form_token;
            const endpoint = process.env.VUE_APP_SYSTEMPAY_API;
            const publicKey = process.env.VUE_APP_SYSTEMPAY_KEY;

            const { KR } = await KRGlue.loadLibrary(
                endpoint,
                publicKey
            )

            await KR.setFormConfig({
                formToken: this.formToken,
                'kr-language': 'fr-FR'
            })

            this.showPaymentButton = false
            this.showPaymentForm = true;
            this.$emit('step-completed', '5'),
            this.$emit('update-title', 'Veuillez saisir vos informations de paiement')

            await KR.onSubmit(this.validatePayment)
            await KR.renderElements('#paymentForm')
            await KR.onError(this.errorPayment)
        },

        getItemPrice(session, childSession) {

            var getCurrentPrice = {}

            session.session.product.prices.map((priceItem) => {

                const startDate = moment(priceItem.start_date).startOf('day');
                const endDate = moment(priceItem.end_date).startOf('day');
                const currentDate = moment().startOf('day');
                const checkDate = currentDate.isBetween(startDate, endDate, null, '[]');

                if (priceItem.end_date && checkDate) {
                    getCurrentPrice[priceItem.type] = parseInt(priceItem.amount_ttc)
                }
                else if (!priceItem.end_date && (startDate <= currentDate)) {
                    getCurrentPrice[priceItem.type] = parseInt(priceItem.amount_ttc)
                }
            })

            if ('AUTO1' in getCurrentPrice) {
                return getCurrentPrice['AUTO1'];
            }

            var numberItems = childSession.filter(element => {
                if (element.session.product.type_id != 2900012 &&
                    element.session.start_date == session.session.start_date &&
                    element.session.location && session.session.location &&
                    element.session.location.address == session.session.location.address) {
                    return element
                }
            }).length

            if (numberItems < 2) {
                return '1MAT' in getCurrentPrice ? getCurrentPrice['1MAT'] : 0
            }
            else if (numberItems == 2) {
                return '2MAT' in getCurrentPrice ? getCurrentPrice['2MAT'] : 0
            }
            else if (numberItems > 2) {
                if ('3MAT' in getCurrentPrice) {
                    return getCurrentPrice['3MAT']
                }
                else if ('2MAT' in getCurrentPrice) {
                    return getCurrentPrice['2MAT']
                } else {
                    return 0
                }
            }

            return 0
        },

        getUnsentSessions() {
            const unsentSessions = [];
            Object.keys(this.sortedCart).forEach(key => {
                this.sortedCart[key].forEach(session => {
                const sessionId = session.session.id_ammon;
                const userId = session.user.infos.id;
                const sessionInfo = `${userId}_${sessionId}`;

                if (!this.getDatalayerInfo.includes(sessionInfo)) {
                    unsentSessions.push(session);
                }
                });
            });
            return unsentSessions;
        },

        getInvoiceId() {
            this.invoiceId = localStorage.getItem(this.invoiceIdKey);
        },

        saveInvoiceId(invoiceId) {
            localStorage.setItem(this.invoiceIdKey, invoiceId);
        },

        sortCart() {
            var cart = this.getCart
            var sortedCart = {}
            var limitCart = {}

            cart.map((session, index) => {
                var childName = session.user.infos.firstName + ' ' + session.user.infos.lastName
                if (!(childName in sortedCart)) {
                    sortedCart[childName] = []
                }
                session.index = index
                sortedCart[childName].push(session)
                if (!(session.user.infos.id in limitCart)) {
                    limitCart[session.user.infos.id] = 0
                }
                if (session.session.product.type_id != 2900012) {
                    limitCart[session.user.infos.id] += 1
                }
            })
            this.$store.dispatch('updateLimitCart', limitCart);
            this.sortedCart = sortedCart
        },

        subscribeUserToCourse() {
            this.loading = true
            this.showPaymentButton = false
            const datas = {
                cart: this.getCart.map((item) => {
                    return { 'sessionId': item.session.id_ammon, 'userId': item.user.infos.id, 'class': item.session.class }
                }),
                user: this.getUser,
            };
            this.$store.dispatch('subscribeUserToCourse', datas)
                .then(() => {
                    this.generateInvoice()
                })
                .catch((error) => console.log(error));
        },

        updateDatalayerWithCurrentCart() {
            const currentCartSessions = [];
            Object.values(this.sortedCart).forEach(sessions => {
                sessions.forEach(session => {
                    currentCartSessions.push(`${session.user.infos.id}_${session.session.id_ammon}`);
                });
            });
            this.$store.dispatch('updateDatalayerInfo', currentCartSessions);
        },

        async validatePayment(paymentData) {
            this.showPaymentForm = false;
            this.createDatalayer('paiement')
            this.loadingPayment = true
            if (paymentData.clientAnswer.orderStatus === "PAID") {
                this.loadingPayment = false
                this.paymentSuccess = true
                localStorage.removeItem(this.invoiceIdKey);
                this.$store.dispatch('deleteCart')
                this.$store.dispatch('deleteDatalayerInfo')
            } else {
                this.paymentError = true
                this.loadingPayment = false
            }
        }

    },

    created() {
        this.getInvoiceId()
        this.sortCart();
  
        if (!this.isCartMatchingDatalayer()) {
            this.subscribeUserToCourse();
        } else {
            this.showPaymentButton = true;
        }
    }

}

</script>

<style>

.recap-card {
    border: 1px solid #e0e0e0; 
    border-radius: 8px
}

.recap-title {
    color: #10757a;
}

.recap-child {
    background-color: #E3F2FD; 
    padding: 8px; 
    border-radius: 4px;
}

.recap-infos {
    font-size: 13px;
    color: #6B6C80;
}

.btn-recall {
    text-decoration: none;
    text-transform: none;
}

.spinner {
    text-align: center;
}

.spinner>div {
    width: 12px;
    height: 12px;
    background-color: #272954;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

.show-summary {
    cursor: pointer;
    text-decoration: underline;
}

.show-summary:hover {
    color: rgb(85, 85, 85);
}

@-webkit-keyframes sk-bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0)
    }

    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}

</style>
