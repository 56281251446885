<template>
    <v-select
        v-model="localValue"
        :label="label"
        :items="items"
        :item-title="itemTitle"
        :item-value="itemValue"
        :chips="chips"
        :closable-chips="closableChips"
        :multiple="multiple"
        :rules="rules"
        :return-object="returnObject"
        variant="outlined"
    ></v-select>
</template>

<script>

export default {

    props: {
        chips: Boolean,
        closableChips: Boolean,
        items: Array,
        itemTitle: String,
        itemValue: String,
        label: String,
        multiple: Boolean,
        returnObject: Boolean,
        rules: Array, 
        value: Object
    },

    data() {
        return {
            localValue: this.value
        };
    },
}

</script>