const state = {
    datalayerInfo: JSON.parse(localStorage.getItem('datalayerInfo')) || [],
};

const getters = {
    getDatalayerInfo: state => state.datalayerInfo,
};

const actions = {
    deleteDatalayerInfo({ commit}) {
        commit('deleteDatalayerInfo')
    },

    updateDatalayerInfo({ commit }, datas) {
        commit('updateDatalayerInfo', datas);
    }
}

const mutations = {
    deleteDatalayerInfo(state) {
        state.datalayerInfo = [],
        localStorage.removeItem('datalayerInfo')
    },

    updateDatalayerInfo(state, datas) {
        state.datalayerInfo = datas
        localStorage.setItem('datalayerInfo', JSON.stringify(datas))
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};