<template>
    <v-select
        v-model="localValue"
        :label="label"
        :items="items"
        :chips="chips"
        :closable-chips="closableChips"
        :multiple="multiple"
        :rules="rules"
        :hide-details="hidedetails"
        variant="outlined"
    ></v-select>
</template>

<script>

export default {

    props: {
        chips: Boolean,
        closableChips: Boolean,
        hidedetails: Boolean,
        items: Array,
        label: String,
        multiple: Boolean,
        rules: Array,
        value: String
    },

    data() {
        return {
            localValue: this.value
        };
    },
}

</script>