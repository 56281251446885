<template>
    <v-row justify=center class="mt-4">
        <v-col cols=9>
            <module-inscription-header step=2 title="Choisissez une préparation" />
            <module-inscription-form class="mt-8" @search-results="updateSearchResults" @update-selected-child="updateSelectedChild" @clear-search-results="clearSearchResults"/>
            <module-inscription-list v-if="isSearchDone" :products="searchResults" :selectedChild="selectedChild" class="mt-3 mb-10" />
            <module-inscription-action v-if="cartItems > 0"></module-inscription-action>
        </v-col>
    </v-row>
</template>

<script>

import ModuleInscriptionHeader from '@/components/layout/HeaderBase.vue';
import ModuleInscriptionForm from '@/components/module-inscription/ModuleInscriptionForm.vue';
import ModuleInscriptionList from '@/components/module-inscription/ModuleInscriptionList.vue';
import ModuleInscriptionAction from '@/components/module-inscription/ModuleInscriptionAction.vue';

import { mapGetters } from 'vuex';

export default {

    components: {
        ModuleInscriptionHeader,
        ModuleInscriptionForm,
        ModuleInscriptionList,
        ModuleInscriptionAction
    },

    data() {
        return {
            cards: 1,
            isSearchDone: false,
            searchResults: [],
            selectedChild: null,
        };
    },

    computed: {

        ...mapGetters([
            'getCart',
        ]),

        cartItems() {
            return this.getCart.length
        }
    },

    methods: {

        clearSearchResults() {
            this.searchResults = [];
            this.isSearchDone = false;
        },

        updateSearchResults(results) {
            this.searchResults = results;
            this.isSearchDone = true;
        },

        updateSelectedChild(results) {
            this.selectedChild = results;
        }

    },
};

</script>