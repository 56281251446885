<template>
    <v-form ref="userForm">
        <v-row>
            <v-col cols="12" sm="4" xl="2" class="py-2">
                <select-field v-model="user.gender" label="Civilité" :items="['M.', 'Mme']" :rules="genderRules"></select-field>
            </v-col>
            <v-col cols="12" sm="8" xl="5" class="py-2">
                <text-field v-model="user.firstname" label="Prénom" :rules="firstNameRules"></text-field>
            </v-col>
            <v-col cols="12" xl="5" class="py-2">
                <text-field v-model="user.lastname" label="Nom" :rules="lastNameRules"></text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="py-2">
                <text-field-phone v-model="user.phone" label="Téléphone" :rules="phoneRules"></text-field-phone>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="py-2">
                <text-field v-model="user.email" label="Email" :rules="emailRules"></text-field>
            </v-col>
        </v-row>
        <div class="text-start text-primary font-weight-bold text-h6 mt-3 mb-5">Comment avez-vous connu Cours Thalès ?</div>
        <radio-button v-model="user.medium" :constants="constants" :rules="mediumRules"></radio-button>
        <v-row class="mt-3">
            <v-col cols="12" class=" text-start">
                <v-btn @click="validateUserForm" color="primary" class="font-weight-bold">
                    V<span class="text-lowercase">alider</span>
                    <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>

import { mapGetters } from 'vuex';

import RadioButton from '@/components/user-creation/UserCreationFormRadioButton.vue'
import SelectField from '@/components/form-base/SelectFieldBase.vue';
import TextField from '@/components/form-base/TextFieldBase.vue';
import TextFieldPhone from '@/components/form-base/TextFieldPhoneBase.vue';

export default {
    
    components: {
        RadioButton,
        SelectField,
        TextField,
        TextFieldPhone
    },

    data() {
        return {
            user: {
                gender: null,
                firstname: '',
                lastname: '',
                email: '',
                phone: '',
                address: '',
                postalCode: '',
                city: '',
                medium: '',
            },
            constants: [],
            genderRules: [ 
                v => !!v || "La civilité est requise",
            ],
            classRules: [ 
                v => !!v || "La classe est requise",
            ],
            mediumRules: [ 
                v => !!v || "Ce champs est requis",
            ],
            phoneRules: [
                v => !!v || "Le numéro de téléphone est requis",
            ],
            emailRules: [ 
                v => !!v || "L'email est requis",
                v => /.+@.+\..+/.test(v) || 'Format invalide'
            ],
            firstNameRules: [ 
                v => !!v || "Le prénom est requis",
            ],
            lastNameRules: [ 
                v => !!v || "Le nom est requis",
            ],
        };
    },

    methods: {
        async validateUserForm () {
            const { valid } = await this.$refs.userForm.validate()

            if(valid) {
                this.$store.dispatch('searchUser', {'email': this.user.email})
                .then(response => {
                    if(response) {
                        this.$router.push({
                            name: 'Login',
                            query: { existingEmail: true }
                        });
                    } else {
                        this.$store.dispatch('saveParent', this.user);
                        this.$router.push({name: 'ChildCreation'})
                    }
                });
               
            }
        },

        getConstants() {
            this.$store.dispatch('getConstants')
            .then(constants => {
                this.constants = constants;
            })
            .catch(error => console.log(error));
        },
    },

    computed: {
        ...mapGetters([
            'getUser',
        ]),
    },

    created() {
        if(this.getUser) {
            this.user = this.getUser;
        } else if(JSON.parse(localStorage.getItem('parentDatas'))) {
            this.user = JSON.parse(localStorage.getItem('parentDatas'));
        }
        this.getConstants();
    },
    
};

</script>