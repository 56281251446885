import axios from 'axios'

const state = {
    isAuth: localStorage.getItem('token') ? true : false,
    selectedChild: localStorage.getItem('selectedChild') || null,
    user: JSON.parse(localStorage.getItem('user')) || JSON.parse(localStorage.getItem('parentDatas')) || null,
};

const getters = {
    getSelectedChild: state => state.selectedChild,
    getUser: state => state.user,
    isAuth: state => state.isAuth
};

const actions = {

    createUser: async (_, child) => {
        return new Promise((resolve, reject) => {
            const params = {
                parent: state.user,
                student: child,
            };
            axios.post(process.env.VUE_APP_API_URL + "ammon/users", params, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                let token = response.data.token
                localStorage.setItem('token', token)
                resolve(token);
            })
            .catch((error) => reject(error));
        });
    },

    createChild: async (_, child) => {
        return new Promise((resolve, reject) => {
            const params = {
                parent: state.user,
                student: child,
            };
            axios.post(process.env.VUE_APP_API_URL + "ammon/users/child", params, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                let token = response.data.token
                localStorage.setItem('token', token)
                resolve(token);
            })
            .catch((error) => reject(error));
        });
    },

    getUser: async ({ commit }, token) => {
        return new Promise((resolve, reject) => {
            axios.get(process.env.VUE_APP_API_URL + "v1/auth/user", {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                localStorage.setItem('user', JSON.stringify(response.data.data))
                commit('saveUser', response.data.data);
                resolve(response);
            })
            .catch((error) => reject(error));
        });
    },

    login: async (_, params) => {
        return new Promise((resolve, reject) => {
            axios.post(process.env.VUE_APP_API_URL + "v1/auth/login", params,{
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                }
            })
            .then((response) => {
                let token = response.data.token
                localStorage.setItem('token', token)
                resolve(token);
            })
            .catch((error) => reject(error));
        });
    },

    logout({ commit }) {
        commit('logout');
    },

    saveSelectedChild({ commit }, child) {
        commit('saveSelectedChild', child);
    },

    saveParent({ commit }, datas) {
        commit('saveParent', datas);
    },

    searchUser: async (_, datas) => {
        return new Promise((resolve, reject) => {
            axios.post(process.env.VUE_APP_API_URL + "ammon/users/search", datas,{
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => reject(error));
        });
    },

}

const mutations = {

    logout(state) {
        if (localStorage.getItem('token')) {
            localStorage.removeItem('token');
            localStorage.clear();
            state.selectedChild = null;
            state.user = null;
            state.isAuth = false;
        }
    },

    saveSelectedChild(state, child) {
        state.selectedChild = child;
        localStorage.setItem('selectedChild', child)
    },

    saveParent(state, datas) {
        state.user = datas;
        localStorage.setItem('parentDatas', JSON.stringify(datas));
    },

    saveUser(state, datas) {
        state.isAuth = true;
        state.user = datas;
    },

};

export default {
	state,
	getters,
	actions,
	mutations
};