<template>
     <v-row justify=center class="mt-4">
        <v-col cols=9>
            <login-header step=1 title="Connexion"/>
            <login-form class="mt-15"/>
        </v-col>
    </v-row>
</template>

<script>

import LoginForm from '@/components/login/LoginForm.vue';
import LoginHeader from '@/components/layout/HeaderBase.vue';

export default {
  components: {
    LoginForm,
    LoginHeader
  },
};

</script>