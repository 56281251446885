<template>
    <v-radio-group v-model="localValue" :rules="rules">
        <v-row>
            <v-col v-for="(constant, index) in constants.slice(0,3)" :key="index" cols=12 md=4 class="py-0">
                <v-radio :label="constant.caption" :value="constant.id"></v-radio>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-for="(constant, index) in constants.slice(3,6)" :key="index" cols=12 md=4 class="py-0">
                <v-radio :label="constant.caption" :value="constant.id"></v-radio>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-for="(constant, index) in constants.slice(6,8)" :key="index" cols=12 md=4 class="py-0">
                <v-radio :label="constant.caption" :value="constant.id"></v-radio>
            </v-col>
        </v-row>
    </v-radio-group>
</template>

<script>

export default {

    props: {
        constants: Array,
        rules: Array,
        value: String
    },

    data() {
        return {
            localValue: this.value
        };
    },
}

</script>