import axios from 'axios'

const state = {

};

const getters = {

};

const actions = {

    generateInvoice: async (_, datas ) => {
        return new Promise((resolve, reject) => {
            axios.post(process.env.VUE_APP_API_URL + "ammon/payment/create", datas, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => reject(error));
        });
    },

    createSystemPayPayment: async (_, datas ) => {
        return new Promise((resolve, reject) => {
            axios.post(process.env.VUE_APP_API_URL + "ammon/payment/create-payment-systempay", datas, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => reject(error));
        });
    },

}

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
};