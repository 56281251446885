<template>
    <v-text-field 
        v-model="localValue" 
        :label="label" 
        :rules="rules"
        variant="outlined" 
    />
</template>

<script>

export default {

    props: {
        label: String,
        rules: Array,
        value: String
    },

    data() {
        return {
            localValue: this.value
        };
    }, 
}

</script>