const state = {
    showInfoPanel: false
};

const getters = {
    getShowInfoPanel: state => state.showInfoPanel,
};

const actions = {

    showInfoPanel({ commit }) {
        commit('showInfoPanel');
    },

    unShowInfoPanel({ commit }) {
        commit('unShowInfoPanel');
    }

}

const mutations = {

    showInfoPanel(state) {
        state.showInfoPanel = true;
    },

    unShowInfoPanel(state) {
        state.showInfoPanel = false
    }

};

export default {
    state,
    getters,
    actions,
    mutations
};