<template>
    <div>
        <v-card-title class="mb-2">
            <div class="d-flex align-center">
                <div class="font-weight-bold title">
                    {{ session.session.product.name }}
                </div>
                <v-icon @click="removeFromCart()" icon="mdi-trash-can-outline" class="ml-3" size="x-small" color="primary"></v-icon>
            </div>
        </v-card-title>
        <v-card-text class="text-start">
            <v-row>
                <v-col v-if="getProductSubtitleDescription(session.session.product)" class="pt-0">
                    <span class="font-weight-bold subtitle subtitle-description">{{ getProductSubtitleDescription(session.session.product) }}</span>
                </v-col>
                <v-col cols=12 class="py-0">
                    <span class="font-weight-medium session-infos"><b>Élève</b> : {{ session.user.infos.firstName }} {{ session.user.infos.lastName }}</span>
                </v-col>
                <v-col cols=12 class="py-0">
                    <span class="font-weight-medium session-infos"><b>Classe</b> : {{ session.session.class }}</span>
                </v-col>
                <v-col v-if="session.session.type_id == typeCourseFormule" cols=12 class="py-0">
                    <span class="font-weight-medium session-infos"><b>Date et centre de stage</b> : Le choix des dates et lieux de stages se fera après l’inscription</span>
                </v-col>
                <v-col v-if="session.session.type_id != typeCourseFormule" cols=12 class="py-0">
                    <span class="font-weight-medium session-infos"><b>Date</b> : {{ formatStartDate(session.session.start_date) }} au {{ formatStartDate(session.session.end_date) }}</span>
                </v-col>
                <v-col cols=12 class="py-0">
                    <span v-if="session.session.location" class="font-weight-medium session-infos"><b>Centre de stage</b> : {{ session.session.location.description }} ({{ session.session.location.city }} {{ session.session.location.postcode }})</span>
                </v-col>
                <v-col cols=12 class="py-0">
                    <span class="font-weight-medium session-infos"><b>Prix</b> : {{ getItemPrice(session, childSession) }} €</span>
                </v-col>
                <v-col v-if="session.session.type_id != typeCourseFormule" cols=12 class="py-0 mt-2">
                    <span class="font-weight-medium "><b>Info pratique :</b> L'emploi du temps sera accessible sur votre espace personnel.</span>
                </v-col>
            </v-row>
        </v-card-text>
    </div>
</template>

<script>

import moment from 'moment';

export default {
    
    props: {
        childSession: Object,
        session: Object,
        index: Number
    },

     data() {
        return {
            descriptionProductSubtitleCode: 1800767,
            typeCourseFormule: 1900341
        }
    },

    methods: {

        getItemPrice(session, childSession) {

            var getCurrentPrice = {}
            
            session.session.product.prices.map((priceItem) => {
                const startDate = moment(priceItem.start_date).startOf('day');
                const endDate = moment(priceItem.end_date).startOf('day');
                const currentDate = moment().startOf('day');
                const checkDate = currentDate.isBetween(startDate, endDate, null, '[]');

                if (priceItem.end_date && checkDate) {
                    getCurrentPrice[priceItem.type] = parseInt(priceItem.amount_ttc);
                } else if (!priceItem.end_date && (startDate <= currentDate)) {
                    getCurrentPrice[priceItem.type] = parseInt(priceItem.amount_ttc);
                }
            });

            if ('AUTO1' in getCurrentPrice) {
                return getCurrentPrice['AUTO1'];
            }

            var numberItems = childSession.filter(element => {
                if(element.session.product.type_id != 2900012 &&
                   element.session.start_date == session.session.start_date &&
                   element.session.location && session.session.location &&
                   element.session.location.address == session.session.location.address)
                {
                    return element
                }
            }).length

            if (numberItems < 2) {
                return '1MAT' in getCurrentPrice ? getCurrentPrice['1MAT'] : 0
            }
            else if (numberItems == 2) {
                return '2MAT' in getCurrentPrice ? getCurrentPrice['2MAT'] : 0
            }
            else if (numberItems > 2) {
                if ('3MAT' in getCurrentPrice) {
                    return getCurrentPrice['3MAT']
                }
                else if ('2MAT' in getCurrentPrice) {
                    return getCurrentPrice['2MAT']
                } else {
                    return 0
                }
            }

            return 0
        },

        getProductSubtitleDescription(product) {
            const description = product.descriptions.find(description => description.category_id == this.descriptionProductSubtitleCode);
            return description ? description.short_description : '';
        },

        formatStartDate(startDate) {
            return moment(startDate).format('DD/MM/YYYY');
        },

        formatEndDate(endDate) {
            return moment(endDate).format('DD/MM/YYYY');
        },

        removeFromCart() {
            this.$store.dispatch('removeFromCart', this.index);
        }
    },
}

</script>

<style scoped>

.subtitle-description{
    color: #10757a;
}

.title {
    font-size: 20px;
    white-space: normal;
    text-align: start;
}

</style>