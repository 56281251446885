<template>
    <div>
        <v-row align="center" justify="space-between" class="d-md-none px-3 mb-5 header-container">
            <div class="responsive-header"></div>
            <v-img :src="require('@/assets/logo-cours-thales-white.png')" width="150px" max-width="150px" class="d-md-none logo-white mt-1 ml-8"></v-img>
            <div class="d-flex align-center step-log mr-10 mt-3">
                <v-icon
                    v-if="isUserLog || step != 1"
                    @click.stop="drawer = !drawer"
                    icon="mdi-menu"
                    color="white"
                ></v-icon>
                <v-navigation-drawer v-model="drawer" temporary overlay>
                    <v-list density="compact" nav>
                        <v-list-item @click="showDetails" prepend-icon="mdi-account-details" title="Récapitulatif"></v-list-item>
                        <v-list-item v-if="isUserLog" @click="logout" prepend-icon="mdi-logout" title="Déconnexion"></v-list-item>
                        <v-list-item @click="drawer = false" prepend-icon="mdi-close" title="Fermer"></v-list-item>
                    </v-list>
                </v-navigation-drawer>
            </div>
        </v-row>

        <v-row align=center justify=space-between class="px-3">
            <v-img :src="require('@/assets/logo-cours-thales.png')" width="150px" max-width="150px" class="d-none d-md-block"></v-img>
            <div class="d-flex align-center">
                <v-btn v-if="isUserLog" @click="logout" class="font-weight-bold text-primary d-none d-md-block" variant="text">
                    D<span class="text-lowercase">éconnexion</span>
                </v-btn>
            </div>
        </v-row>
        <v-row class="mt-12 d-md-none">
            <v-col cols="12" class="text-left pb-0">
                <div class="title-form-mobile text-primary">Formulaire d'inscription</div>
            </v-col>
            <v-col cols="12" class="text-left pt-0">
                <div class="text-primary text-h6 subtitle-contact">Contactez-nous au 01 42 05 41 36 si besoin.</div>
            </v-col>
        </v-row>
        <v-row class="mt-6 d-none d-md-block">
            <v-col cols="12" class="text-left pb-0">
                <div class="title-form text-primary">Formulaire d'inscription</div>
            </v-col>
            <v-col cols="12" class="text-left pt-0">
                <div class="text-primary text-h6 subtitle-contact">Contactez-nous au 01 42 05 41 36 si besoin.</div>
            </v-col>
        </v-row>
        <stepper :step="step" class="mt-5"/>
        <v-row align=end class="mt-4 px-3">
            <div class="text-start font-weight-normal title mr-6">{{ title }}</div>
        </v-row>
                
    </div>
</template>

<script>

import Stepper from '@/components/layout/StepperBase.vue';

export default {

    components: {
        Stepper,
    },

    props: {
        step: String,
        title: String,
        alreadyClient: Boolean,
    },

    data() {
        return {
            drawer: false,
            isUserLog: false,
            addNewChild: false
        }
    },

    methods: {

        logout() {
            this.$store.dispatch('resetCart')
            this.$store.dispatch('logout')
            .then(() => {
                this.$router.push({name: 'UserCreation'})
            })
        },

        showDetails() {
            this.$store.dispatch('showInfoPanel');
        }

    },

    created() {
        this.isUserLog = localStorage.getItem('token') ? true : false
        this.addNewChild = this.$route.query.action === 'add-new-child' ? true : false
    }
}

</script>

<style scoped>

.header-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: #272954;
    padding: 10px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.responsive-header {
    background-image: url(./../../assets/panel-info-background.jpg);
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 90px;
}

.responsive-header::after {
    height: 90px;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #272954;
    opacity: 0.8;
}

.logo-white {
    z-index: 10;
}

.details {
    font-size: 15px;
}

.step-log {
    z-index: 10;
}

.title-form {
    font-size: 36px;
}

.title-form-mobile {
    font-size: 26px;
}

.title {
    font-size: 32px;
    color: #10757a;
}

.login-link {
    line-height: 38px;
    font-size: 16px;
    color: #DC3545;
}

.subtitle-contact {
    font-weight: 400 !important;
}

</style>
