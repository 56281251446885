<template>
    <v-card class="rounded-xl mt-0 border-primary-card" :class="$vuetify.display.smAndUp ? 'px-7 py-4' : 'pa-3'">
        <div v-for="(childSession, index) in sortedCart" :key="index">
            <summary-module-card 
                v-for="(session, index) in childSession" 
                :key="index"
                class="my-3"
                :session="session"
                :childSession="childSession"
                :index="session.index"
            />
        </div>
        <v-card-title class="mb-2">
            <div class="d-flex align-center">
                <div class="font-weight-bold title">
                    Total : {{ totalPrice }} €
                </div>
            </div>
        </v-card-title>
    </v-card>
</template>

<script>

import { mapGetters } from 'vuex';

import moment from 'moment';

import SummaryModuleCard from '@/components/summary/SummaryModuleCard.vue';

export default {

    components: {
        SummaryModuleCard
    },

    data() {
        return {
            sortedCart: null,
        }
    },

    computed: {

        ...mapGetters([
            'getCart',
        ]),

        totalPrice() {
            var price = 0;
            Object.keys(this.sortedCart).map(key => {
                this.sortedCart[key].map(session => {
                    price += parseInt(this.getItemPrice(session, this.sortedCart[key]))
                })
            })
            return price
        }
        
    },

    methods: {

        getItemPrice(session, childSession) {

            var getCurrentPrice = {}
            
            session.session.product.prices.map((priceItem) => {
                const startDate = moment(priceItem.start_date).startOf('day');
                const endDate = moment(priceItem.end_date).startOf('day');
                const currentDate = moment().startOf('day');
                const checkDate = currentDate.isBetween(startDate, endDate, null, '[]');

                if (priceItem.end_date && checkDate) {
                    getCurrentPrice[priceItem.type] = parseInt(priceItem.amount_ttc);
                } else if (!priceItem.end_date && (startDate <= currentDate)) {
                    getCurrentPrice[priceItem.type] = parseInt(priceItem.amount_ttc);
                }
            });

            if ('AUTO1' in getCurrentPrice) {
                return getCurrentPrice['AUTO1'];
            }

            var numberItems = childSession.filter(element => {
                if(element.session.product.type_id != 2900012 &&
                   element.session.start_date == session.session.start_date &&
                   element.session.location && session.session.location &&
                   element.session.location.address == session.session.location.address)
                {
                    return element
                }
            }).length

            if (numberItems < 2) {
                return '1MAT' in getCurrentPrice ? getCurrentPrice['1MAT'] : 0
            }
            else if (numberItems == 2) {
                return '2MAT' in getCurrentPrice ? getCurrentPrice['2MAT'] : 0
            }
            else if (numberItems > 2) {
                if ('3MAT' in getCurrentPrice) {
                    return getCurrentPrice['3MAT']
                }
                else if ('2MAT' in getCurrentPrice) {
                    return getCurrentPrice['2MAT']
                } else {
                    return 0
                }
            }

            return 0
        },

        sortCart() {
            var cart = this.getCart
            var sortedCart = {}

            cart.map((session, index) => {
                var childName = session.user.infos.firstName + ' ' + session.user.infos.lastName
                if(!(childName in sortedCart)) {
                   sortedCart[childName] = []
                }
                session.index = index
                sortedCart[childName].push(session)
            })
            this.sortedCart = sortedCart
        }

    },

    created() {
        this.sortCart();
    },

    watch: {

        getCart: {
            handler() {
                this.sortCart()
            },
            deep: true
        },

    },

}

</script>


<style scoped>

.student {
    font-size: 20px;
}

.border-primary-card {
  border: 1px solid #272954;
}

</style>