<template>
    <v-row class="px-3 flex-column mb-5">
        <router-link to="/payment" class="text-decoration-none mb-3">
            <v-btn color="secondary" class="font-weight-bold d-none d-sm-block">
                C<span class="text-lowercase">onfirmer cette inscription</span>
                <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
            </v-btn>
            <v-btn color="secondary" class="font-weight-bold d-sm-none w-100">
                C<span class="text-lowercase">onfirmer cette inscription</span>
                <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
            </v-btn>
        </router-link>
        <router-link to="/module-inscription" class="text-decoration-none">
            <v-btn color="primary" class="font-weight-bold d-none d-sm-block">
                A<span class="text-lowercase">jouter une formation</span>
                <v-icon icon="mdi-plus" class="ml-1"></v-icon>
            </v-btn>
            <v-btn color="primary" class="font-weight-bold d-sm-none w-100">
                A<span class="text-lowercase">jouter une formation</span>
                <v-icon icon="mdi-plus" class="ml-1"></v-icon>
            </v-btn>
        </router-link>  
    </v-row>
</template>