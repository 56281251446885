<template>
    <v-row justify=center class="mt-4">
        <v-col cols=9>
            <child-creation-header step=1 title="Vos coordonnées" :alreadyClient="true" />
            <child-creation-form class="mb-6" />
        </v-col>
    </v-row>
</template>

<script>

import ChildCreationHeader from '@/components/layout/HeaderBase.vue';
import ChildCreationForm from '@/components/child-creation/ChildCreationForm.vue';

export default {

    components: {
        ChildCreationHeader,
        ChildCreationForm,
    },

};

</script>