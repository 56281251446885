<template>
    <v-row justify=center class="mt-4">
        <v-col cols=9>
            <payment-header :step="currentStep" :title="currentTitle" />
            <payment-form @step-completed="handleStepCompletion" @update-title="handleUpdateTitle"/>
        </v-col>
    </v-row>
</template>

<script>

import PaymentForm from '@/components/payment/PaymentForm.vue';
import PaymentHeader from '@/components/layout/HeaderBase.vue';

export default {

    components: {
        PaymentForm,
        PaymentHeader
    },

    data() {
        return {
            currentStep: '4',
            currentTitle: 'Enregistrement de votre pré-inscription'
        };
    },

    methods: {
        handleStepCompletion(newStep) {
            this.currentStep = newStep;
        },

        handleUpdateTitle(newTitle) {
            this.currentTitle = newTitle;
        },
    }
};

</script>