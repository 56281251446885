import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import Login from '@/views/LoginPage.vue';
import ChildCreation from '@/views/ChildCreationPage.vue';
import UserCreation from '@/views/UserCreationPage.vue';
import MaintenanceComponent from '@/views/MaintenancePage.vue';
import ModuleInscription from '@/views/ModuleInscriptionPage.vue';
import Summary from '@/views/SummaryPage.vue';
import Payment from '@/views/PaymentPage.vue';

const ifAuthenticated = (to, from, next) => {
    if (store.getters.isAuth) {
        next()
        return
    }
    next('/user-creation')
}

const ifUnAuthenticated = (to, from, next) => {
  if (!store.getters.isAuth) {
      next()
      return
  }
  next({
    path: '/module-inscription',
    query: { isAuth: true }
  })
}

const maintenanceGuard = (to, from, next) => {
  const maintenanceMode = false;

  if (to.path === '/maintenance') {
    next();
  } else if (maintenanceMode) {
    next('/maintenance');
  } else {
    next();
  }
};

const routes = [
    {
      path: '/',
      redirect: '/user-creation',
      beforeEnter: ifUnAuthenticated
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/child-creation',
      name: 'ChildCreation',
      component: ChildCreation,
    },
    {
      path: '/user-creation',
      name: 'UserCreation',
      component: UserCreation,
      beforeEnter: ifUnAuthenticated
    },
    {
      path: '/module-inscription',
      name: 'ModuleInscription',
      component: ModuleInscription,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/payment',
      name: 'Payment',
      component: Payment,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/summary',
      name: 'Summary',
      component: Summary,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/maintenance',
      name: 'MaintenanceComponent',
      component: MaintenanceComponent
    }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(maintenanceGuard);

export default router
