import "@mdi/font/css/materialdesignicons.css";
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
    components,
    directives,
    theme: {
        defaultTheme: 'myCustomTheme',
        themes: {
            myCustomTheme: {
                dark: false,
                colors: {
                    primary: "#272954",
                    secondary: "#ea4899",
                    tertiary: "#10757a",
                    quaternary: "#ecf2f2"

                },
            },
        }
    },
})

export default vuetify;