const state = {
    cart: JSON.parse(localStorage.getItem('cart')) || [],
    limitCart: null
};

const getters = {
    getCart: state => state.cart,
    getlimitCart: state => state.limitCart
};

const actions = {

    addToCart({ commit }, datas) {
        commit('addToCart', datas);
    },

    deleteCart({ commit}) {
        commit('deleteCart')
    },

    removeFromCart({ commit }, index) {
        commit('removeFromCart', index);
    },

    resetCart({ commit }) {
        commit('resetCart')
    },

    updateLimitCart({ commit }, datas) {
        commit('updateLimitCart', datas);
    }

}

const mutations = {
    addToCart(state, datas) {
        state.cart.push(datas);
        localStorage.setItem('cart', JSON.stringify(state.cart))
    },

    deleteCart(state) {
        state.cart = [],
        localStorage.removeItem('cart')
    },

    removeFromCart(state, index) {
        state.cart.splice(index, 1)
        localStorage.setItem('cart', JSON.stringify(state.cart))
    },

    resetCart(state) {
        state.cart = []
    },

    updateLimitCart(state, datas) {
        state.limitCart = datas
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};